<template>
  <section id="support-overview">
    <b-card>
      <v-select v-model="selectedEmployees" label="title" multiple :options="allEmployees"
        @input="changeSelectedEmployees" />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">
          Useful links
          <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-useful-links" />
        </h4>
        <b-popover target="popover-useful-links" triggers="hover" placement="bottom">
          <span>No data.</span>
        </b-popover>
      </b-card-header>
      <b-card-body>
        <b-badge href="https://g.co/kgs/cRUUVa2" target="_blank" variant="primary">
          <feather-icon icon="LinkIcon" class="mr-25" />
          <span>Google reviews</span>
        </b-badge>&nbsp;
        <b-badge href="https://www.trustedshops.de/bewertung/info_XB1210A7BE5861B144FE273723AB33D46.html?sort=date"
          target="_blank" variant="primary">
          <feather-icon icon="LinkIcon" class="mr-25" />
          <span>Customer reviews</span>
        </b-badge>&nbsp;
        <b-badge href="https://app.sipgate.com/channel-team-lead-overview/6e2ef927-5cdf-4fa9-9db0-4a84260b00b3"
          target="_blank" variant="primary">
          <feather-icon icon="LinkIcon" class="mr-25" />
          <span>Phone dashboard</span>
        </b-badge>
      </b-card-body>
    </b-card>
    <b-overlay :show="shoAbsoluteValues" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Absolute values
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-absolute-values" />
          </h4>
          <b-popover target="popover-absolute-values" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-form-radio-group name="radios-btn-default" button-variant="primary" buttons class="btn-block"
              v-model="selectedDateRange" :options="optionsDateRange" @change="changeDateRange" />
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="ordersOffersByEmployeeTable.currentPage" :items="ordersOffersByEmployeeTable.items"
          :fields="ordersOffersByEmployeeTable.fields" :sort-by.sync="ordersOffersByEmployeeTable.sortBy"
          :sort-desc.sync="ordersOffersByEmployeeTable.sortDesc"
          :sort-direction="ordersOffersByEmployeeTable.sortDirection" :filter="ordersOffersByEmployeeTable.filter"
          :filter-included-fields="ordersOffersByEmployeeTable.filterOn"
          @row-clicked="ordersOffersByEmployeeTableRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="ordersOffersByEmployeeTable.currentPage"
              :total-rows="ordersOffersByEmployeeTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="employee-detail-modal" size="xl" :title="employeeName" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @shown="onEmployeeDetailsModalShown"
      @hidden="resetEmployeeDetailModal">
      <b-row>
        <b-col xl="6">
          <b-overlay :show="showEmployeeOffers" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Offers
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-offers" />
                </h4>
                <b-popover target="popover-offers" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <b-table striped hover responsive class="position-relative"
                :current-page="employeeOffersTable.currentPage" :items="employeeOffersTable.items"
                :fields="employeeOffersTable.fields" :sort-by.sync="employeeOffersTable.sortBy"
                :sort-desc.sync="employeeOffersTable.sortDesc" :sort-direction="employeeOffersTable.sortDirection"
                :filter="employeeOffersTable.filter" :filter-included-fields="employeeOffersTable.filterOn"
                @sort-changed="employeeOffersTableSortChanged" />
              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- pagination -->
                <div>
                  <b-pagination v-model="employeeOffersTable.currentPage" :total-rows="employeeOffersTable.totalRows"
                    first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                    @change="handleEmployeeOffersTablePageChange">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col xl="6">
          <b-overlay :show="showEmployeeOrders" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Facturized Orders
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders" />
                </h4>
                <b-popover target="popover-orders" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <b-table striped hover responsive class="position-relative"
                :current-page="employeeOrdersTable.currentPage" :items="employeeOrdersTable.items"
                :fields="employeeOrdersTable.fields" :sort-by.sync="employeeOrdersTable.sortBy"
                :sort-desc.sync="employeeOrdersTable.sortDesc" :sort-direction="employeeOrdersTable.sortDirection"
                :filter="employeeOrdersTable.filter" :filter-included-fields="employeeOrdersTable.filterOn"
                @sort-changed="employeeOrdersTableSortChanged" />
              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- pagination -->
                <div>
                  <b-pagination v-model="employeeOrdersTable.currentPage" :total-rows="employeeOrdersTable.totalRows"
                    first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                    @change="handleEmployeeOrdersTablePageChange">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Top customers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-top-customers" />
          </h4>
          <b-popover target="popover-top-customers" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="topCustomersChart" :options="topCustomersChart" style="width: 100%; height: 500px;" />
      </b-card>
    </b-modal>
    <b-overlay :show="shoDataOverview" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover Current Month
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-turnover-current-month" />
              </h4>
              <b-popover target="popover-turnover-current-month" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverCurrentMonthChart" :options="turnoverCurrentMonthChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="8" md="8">
          <b-card no-body>
            <b-table striped hover responsive class="position-relative"
              :current-page="turnoverCurrentPrevMonthTable.currentPage" :items="turnoverCurrentPrevMonthTable.items"
              :fields="turnoverCurrentPrevMonthTable.fields" :sort-by.sync="turnoverCurrentPrevMonthTable.sortBy"
              :sort-desc.sync="turnoverCurrentPrevMonthTable.sortDesc"
              :sort-direction="turnoverCurrentPrevMonthTable.sortDirection"
              :filter="turnoverCurrentPrevMonthTable.filter"
              :filter-included-fields="turnoverCurrentPrevMonthTable.filterOn"
              @row-clicked="turnoverCurrentPrevMonthTableRowClicked" />
          </b-card>
        </b-col>
      </b-row>
      <b-modal ref="employee-details-modal" size="xl" :title="modalName" cancel-title="Close"
        cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetEmployeeDetailsModal">
        <b-overlay :show="showOrdersByEmployee" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-table striped hover responsive class="position-relative"
              :current-page="ordersByEmployeeTable.currentPage" :items="ordersByEmployeeTable.items"
              :fields="ordersByEmployeeTable.fields" :sort-by.sync="ordersByEmployeeTable.sortBy"
              :sort-desc.sync="ordersByEmployeeTable.sortDesc" :sort-direction="ordersByEmployeeTable.sortDirection"
              :filter="ordersByEmployeeTable.filter" :filter-included-fields="ordersByEmployeeTable.filterOn"
              @sort-changed="ordersByEmployeeTableSortChanged" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="ordersByEmployeeTable.currentPage" :total-rows="ordersByEmployeeTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleOrdersByEmployeeTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-modal>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover Last Quarter
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-turnover-quarter" />
              </h4>
              <b-popover target="popover-turnover-quarter" triggers="hover" placement="bottom">
                <span>{{ lastQuarterRange.from_date }} to {{ lastQuarterRange.to_date }}</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverLastQuarterChart" :options="turnoverLastQuarterChart"
              style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="turnoverLastQuarterTable.currentPage" :items="turnoverLastQuarterTable.items"
                :fields="turnoverLastQuarterTable.fields" :sort-by.sync="turnoverLastQuarterTable.sortBy"
                :sort-desc.sync="turnoverLastQuarterTable.sortDesc"
                :sort-direction="turnoverLastQuarterTable.sortDirection" :filter="turnoverLastQuarterTable.filter"
                :filter-included-fields="turnoverLastQuarterTable.filterOn"
                @row-clicked="turnoverLastQuarterTableRowClicked" />
            </b-card>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover Current Year
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-current-year" />
              </h4>
              <b-popover target="popover-current-year" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverCurrentYearChart" :options="turnoverCurrentYearChart"
              style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="turnoverCurrentYearTable.currentPage" :items="turnoverCurrentYearTable.items"
                :fields="turnoverCurrentYearTable.fields" :sort-by.sync="turnoverCurrentYearTable.sortBy"
                :sort-desc.sync="turnoverCurrentYearTable.sortDesc"
                :sort-direction="turnoverCurrentYearTable.sortDirection" :filter="turnoverCurrentYearTable.filter"
                :filter-included-fields="turnoverCurrentYearTable.filterOn"
                @row-clicked="turnoverCurrentYearTableRowClicked" />
            </b-card>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Offers Last Month
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-offers-month" />
              </h4>
              <b-popover target="popover-offers-month" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="offersLastMonthChart" :options="offersLastMonthChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="offersCountLastMonthTable.currentPage" :items="offersCountLastMonthTable.items"
                :fields="offersCountLastMonthTable.fields" :sort-by.sync="offersCountLastMonthTable.sortBy"
                :sort-desc.sync="offersCountLastMonthTable.sortDesc"
                :sort-direction="offersCountLastMonthTable.sortDirection" :filter="offersCountLastMonthTable.filter"
                :filter-included-fields="offersCountLastMonthTable.filterOn" />
            </b-card>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Offers Last Quarter
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-offers-quarter" />
              </h4>
              <b-popover target="popover-offers-quarter" triggers="hover" placement="bottom">
                <span>{{ lastQuarterRange.from_date }} to {{ lastQuarterRange.to_date }}</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="offersLastQuarterChart" :options="offersLastQuarterChart"
              style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="offersCountLastQuarterTable.currentPage" :items="offersCountLastQuarterTable.items"
                :fields="offersCountLastQuarterTable.fields" :sort-by.sync="offersCountLastQuarterTable.sortBy"
                :sort-desc.sync="offersCountLastQuarterTable.sortDesc"
                :sort-direction="offersCountLastQuarterTable.sortDirection" :filter="offersCountLastQuarterTable.filter"
                :filter-included-fields="offersCountLastQuarterTable.filterOn" />
            </b-card>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders Last Month
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-month" />
              </h4>
              <b-popover target="popover-orders-month" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersLastMonthChart" :options="ordersLastMonthChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="ordersCountLastMonthTable.currentPage" :items="ordersCountLastMonthTable.items"
                :fields="ordersCountLastMonthTable.fields" :sort-by.sync="ordersCountLastMonthTable.sortBy"
                :sort-desc.sync="ordersCountLastMonthTable.sortDesc"
                :sort-direction="ordersCountLastMonthTable.sortDirection" :filter="offersCountLastMonthTable.filter"
                :filter-included-fields="ordersCountLastMonthTable.filterOn" />
            </b-card>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders Last Quarter
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-quarter" />
              </h4>
              <b-popover target="popover-orders-quarter" triggers="hover" placement="bottom">
                <span>{{ lastQuarterRange.from_date }} to {{ lastQuarterRange.to_date }}</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersLastQuarterChart" :options="ordersLastQuarterChart"
              style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="ordersCountLastQuarterTable.currentPage" :items="ordersCountLastQuarterTable.items"
                :fields="ordersCountLastQuarterTable.fields" :sort-by.sync="ordersCountLastQuarterTable.sortBy"
                :sort-desc.sync="ordersCountLastQuarterTable.sortDesc"
                :sort-direction="ordersCountLastQuarterTable.sortDirection" :filter="ordersCountLastQuarterTable.filter"
                :filter-included-fields="ordersCountLastQuarterTable.filterOn" />
            </b-card>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders intake
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-intake" />
              </h4>
              <b-popover target="popover-orders-intake" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts :options="ordersIntakeChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative" :current-page="ordersIntakeTable.currentPage"
                :per-page="10" :items="ordersIntakeTable.items" :fields="ordersIntakeTable.fields"
                :sort-by.sync="ordersIntakeTable.sortBy" :sort-desc.sync="ordersIntakeTable.sortDesc"
                :sort-direction="ordersIntakeTable.sortDirection" :filter="ordersIntakeTable.filter"
                :filter-included-fields="ordersIntakeTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders intake by country group
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-intake-country-group" />
              </h4>
              <b-popover target="popover-orders-intake-country-group" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts :options="ordersIntakeByCountryGroupChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="ordersIntakeByCountryGroupTable.currentPage" :per-page="10"
                :items="ordersIntakeByCountryGroupTable.items" :fields="ordersIntakeByCountryGroupTable.fields"
                :sort-by.sync="ordersIntakeByCountryGroupTable.sortBy"
                :sort-desc.sync="ordersIntakeByCountryGroupTable.sortDesc"
                :sort-direction="ordersIntakeByCountryGroupTable.sortDirection"
                :filter="ordersIntakeByCountryGroupTable.filter"
                :filter-included-fields="ordersIntakeByCountryGroupTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Facturized Orders
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders-intake" />
              </h4>
              <b-popover target="popover-orders-intake" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts :options="facturizedOrdersChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="facturizedOrdersTable.currentPage" :per-page="10" :items="facturizedOrdersTable.items"
                :fields="facturizedOrdersTable.fields" :sort-by.sync="facturizedOrdersTable.sortBy"
                :sort-desc.sync="facturizedOrdersTable.sortDesc" :sort-direction="facturizedOrdersTable.sortDirection"
                :filter="facturizedOrdersTable.filter" :filter-included-fields="facturizedOrdersTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Facturized orders amount by country group
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-facturized-orders-amount-country-group" />
              </h4>
              <b-popover target="popover-facturized-orders-amount-country-group" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts :options="facturizedOrdersByCountryGroupChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="facturizedOrdersByCountryGroupTable.currentPage" :per-page="10"
                :items="facturizedOrdersByCountryGroupTable.items" :fields="facturizedOrdersByCountryGroupTable.fields"
                :sort-by.sync="facturizedOrdersByCountryGroupTable.sortBy"
                :sort-desc.sync="facturizedOrdersByCountryGroupTable.sortDesc"
                :sort-direction="facturizedOrdersByCountryGroupTable.sortDirection"
                :filter="facturizedOrdersByCountryGroupTable.filter"
                :filter-included-fields="facturizedOrdersByCountryGroupTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Facturized orders amount by platform
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-facturized-orders-amount-platform" />
              </h4>
              <b-popover target="popover-facturized-orders-amount-platform" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts :options="facturizedOrdersByPlatformChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="facturizedOrdersByPlatformTable.currentPage" :per-page="10"
                :items="facturizedOrdersByPlatformTable.items" :fields="facturizedOrdersByPlatformTable.fields"
                :sort-by.sync="facturizedOrdersByPlatformTable.sortBy"
                :sort-desc.sync="facturizedOrdersByPlatformTable.sortDesc"
                :sort-direction="facturizedOrdersByPlatformTable.sortDirection"
                :filter="facturizedOrdersByPlatformTable.filter"
                :filter-included-fields="facturizedOrdersByPlatformTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Offers count by country group
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-offers-count-country-group" />
              </h4>
              <b-popover target="popover-offers-count-country-group" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts :options="offersCountByCountryGroupChart" style="width: 100%; height: 500px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="offersCountByCountryGroupTable.currentPage" :per-page="10"
                :items="offersCountByCountryGroupTable.items" :fields="offersCountByCountryGroupTable.fields"
                :sort-by.sync="offersCountByCountryGroupTable.sortBy"
                :sort-desc.sync="offersCountByCountryGroupTable.sortDesc"
                :sort-direction="offersCountByCountryGroupTable.sortDirection"
                :filter="offersCountByCountryGroupTable.filter"
                :filter-included-fields="offersCountByCountryGroupTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BRow,
  BCol,
  BOverlay,
  BTable,
  BModal,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BButtonGroup,
  BBadge,
  BFormRadioGroup,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const currentMonthIndex = new Date().getMonth() + 1;
const threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(currentDate.getMonth() - 2);
const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(currentDate.getMonth() - 5);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDay = (date) => String(date.getDate()).padStart(2, '0');
const formatDate = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

const allMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
// Calculate the indices of the last three months
const monthNames = [
  allMonthNames[(currentMonthIndex - 3 + 12) % 12],
  allMonthNames[(currentMonthIndex - 2 + 12) % 12],
  allMonthNames[currentMonthIndex - 1]
];
const countryGroups = ['Outside EU', 'Germany', 'EU (except Germany)'];
const platforms = ['Amazon', 'Onlineshop', 'JTL-Wawi'];

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BRow,
    BCol,
    BOverlay,
    BTable,
    BModal,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BButtonGroup,
    BBadge,
    BFormRadioGroup,
    vSelect,
    ECharts,
  },
  data() {
    return {
      shoAbsoluteValues: true,
      shoDataOverview: true,
      showOrdersIntakeAndFacturizedOrders: true,
      showOrdersByEmployee: true,
      showEmployeeOffers: true,
      showEmployeeOrders: true,
      selectedEmployees: [],
      allEmployees: [],
      dateRange: `${threeMonthsAgo.getFullYear()}-${formatMonth(threeMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDay(currentDate)}`,
      selectedDateRange: 'last_six_months',
      optionsDateRange: [
        { text: 'Last six months', value: 'last_six_months' },
        { text: 'Current year', value: 'current_year' },
      ],
      modalName: '',
      lastQuarterRange: '',
      queryParams: {},
      queryParamsOrdersByEmployee: {},
      employeeName: '',
      ordersOffersByEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'name', sortable: true },
          {
            key: 'offers_count',
            label: 'Offers Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'orders_count',
            label: 'Facturized Orders Number',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'conversion_rate',
            label: 'Conversion Rate',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_total_qty',
            label: 'Offers Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'orders_total_qty',
            label: 'Orders Total Qty',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
          {
            key: 'offers_value',
            label: 'Offers Total Amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'open_orders_value',
            label: 'open orders',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_ratio',
            label: 'turnover ratio',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)} %`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      employeeOffersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'offer_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no', sortable: true },
          {
            key: 'offer_amount',
            label: 'offer amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'customer_name', label: 'customer name', sortable: true },
          {
            key: 'offer_date',
            label: 'offer date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      employeeOrdersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'order_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'invoice_no', label: 'invoice no', sortable: true },
          { key: 'order_no', label: 'order no', sortable: true },
          {
            key: 'order_amount',
            label: 'order amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'customer_name', label: 'customer name', sortable: true },
          {
            key: 'order_date',
            label: 'order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      topCustomersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      turnoverCurrentMonthChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Turnover',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      turnoverCurrentPrevMonthTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover_current_month',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'employee', sortable: true },
          {
            key: 'turnover_prev_month',
            label: 'turnover prev month',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_current_month',
            label: 'turnover current month',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'change_month',
            label: 'change',
            sortable: true,
            formatter: val => `${this.$formatCurrencyNumber(val)}`,
          },
          {
            key: 'open_orders_value',
            label: 'open orders amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      turnoverCurrentYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
      turnoverCurrentYearTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'employee',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'employee', sortable: true },
          {
            key: 'turnover_current_year',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      turnoverLastQuarterChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
      turnoverLastQuarterTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'employee',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'employee', sortable: true },
          {
            key: 'turnover_last_quarter',
            label: 'turnover',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      offersLastMonthChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
      offersCountLastMonthTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'employee',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'employee', sortable: true },
          {
            key: 'offers_count_last_month',
            label: 'offers count',
            sortable: true,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      offersLastQuarterChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
      offersCountLastQuarterTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'employee',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'employee', sortable: true },
          {
            key: 'offers_count_last_quarter',
            label: 'offers count',
            sortable: true,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      ordersLastMonthChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
      ordersCountLastMonthTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'employee',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'employee', sortable: true },
          {
            key: 'orders_count_last_month',
            label: 'orders count',
            sortable: true,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      ordersLastQuarterChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        grid: {
          bottom: 100 // Adds extra space at the bottom of the chart
        },
        series: [],
      },
      ordersCountLastQuarterTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'employee',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'employee', label: 'employee', sortable: true },
          {
            key: 'orders_count_last_quarter',
            label: 'orders count',
            sortable: true,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      ordersIntakeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersIntakeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersIntakeByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';

            // Process, filter, and format each param item in one step
            const order = [
              "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
              "Germany-2023", "Germany-2024", "Germany-2023 MTD",
              "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
            ];

            const items = params
              .filter(param => param.value !== null && param.value !== undefined && param.value !== '' && param.value !== 0)
              .map(param => `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €`);

            // Sort items based on the custom order
            items.sort((a, b) => {
              const aIndex = order.findIndex(key => a.includes(key));
              const bIndex = order.findIndex(key => b.includes(key));
              return aIndex - bIndex;
            });

            // Combine sorted items with line breaks and return
            return result + items.join('<br/>');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersIntakeByCountryGroupTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      facturizedOrdersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      facturizedOrdersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      facturizedOrdersByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';

            // Process, filter, and format each param item in one step
            const order = [
              "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
              "Germany-2023", "Germany-2024", "Germany-2023 MTD",
              "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
            ];

            const items = params
              .filter(param => param.value !== null && param.value !== undefined && param.value !== '' && param.value !== 0)
              .map(param => `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €`);

            // Sort items based on the custom order
            items.sort((a, b) => {
              const aIndex = order.findIndex(key => a.includes(key));
              const bIndex = order.findIndex(key => b.includes(key));
              return aIndex - bIndex;
            });

            // Combine sorted items with line breaks and return
            return result + items.join('<br/>');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      facturizedOrdersByCountryGroupTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      facturizedOrdersByPlatformChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      facturizedOrdersByPlatformTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      offersCountByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';

            // Process, filter, and format each param item in one step
            const order = [
              "Outside EU-2023", "Outside EU-2024", "Outside EU-2023 MTD",
              "Germany-2023", "Germany-2024", "Germany-2023 MTD",
              "EU (except Germany)-2023", "EU (except Germany)-2024", "EU (except Germany)-2023 MTD"
            ];

            const items = params
              .filter(param => param.value !== null && param.value !== undefined && param.value !== '' && param.value !== 0)
              .map(param => `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}`);

            // Sort items based on the custom order
            items.sort((a, b) => {
              const aIndex = order.findIndex(key => a.includes(key));
              const bIndex = order.findIndex(key => b.includes(key));
              return aIndex - bIndex;
            });

            // Combine sorted items with line breaks and return
            return result + items.join('<br/>');
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: monthNames,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      offersCountByCountryGroupTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      ordersByEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'number', label: 'number', sortable: true },
          { key: 'customer_name', label: 'customer name', sortable: true },
          {
            key: 'turnover',
            label: 'order amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'created_date',
            label: 'order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getEmployeeNames();

      let fromDateSelectedYear;
      let toDateSelectedYear;
      if (this.selectedDateRange == 'last_six_months') {
        fromDateSelectedYear = `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01`;
        toDateSelectedYear = `${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDay(currentDate)}`;
      }
      else {
        fromDateSelectedYear = `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01`;
        toDateSelectedYear = `${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDay(currentDate)}`;
      }

      const fromDateSelectedYearDate = new Date(fromDateSelectedYear);
      const toDateSelectedYearDate = new Date(toDateSelectedYear);
      const fromDatePrevYearDate = new Date(fromDateSelectedYearDate.getFullYear() - 1, fromDateSelectedYearDate.getMonth(), fromDateSelectedYearDate.getDate());
      const toDatePrevYearDate = new Date(toDateSelectedYearDate.getFullYear() - 1, toDateSelectedYearDate.getMonth(), toDateSelectedYearDate.getDate());

      this.queryParams.from_date = fromDateSelectedYear;
      this.queryParams.to_date = toDateSelectedYear;
      this.queryParams.from_date_prev_year = formatDate(fromDatePrevYearDate);
      this.queryParams.to_date_prev_year = formatDate(toDatePrevYearDate);

      this.queryParams.selected_employees = this.selectedEmployees;

      await this.getOrdersOffersByEmployee();

      this.lastQuarterRange = this.getLastQuarterDateRange();

      await this.getTurnoversByEmployees();
      await this.getOrdersIntakeAndFacturizedOrders();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    getFromToDates() {
      const qParamsSelectedYear = {};
      const qParamsPrevYear = {};

      const currentDate = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(currentDate.getMonth() - 2);

      // Calculate the first day of the month three months ago
      const fromDateSelectedYear = new Date(threeMonthsAgo.getFullYear(), threeMonthsAgo.getMonth(), 1);
      // Calculate the last day of the current month
      const toDateSelectedYear = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      // Calculate the same period for the previous year
      const fromDatePrevYear = new Date(fromDateSelectedYear.getFullYear() - 1, fromDateSelectedYear.getMonth(), fromDateSelectedYear.getDate());
      const toDatePrevYear = new Date(toDateSelectedYear.getFullYear() - 1, toDateSelectedYear.getMonth(), toDateSelectedYear.getDate());

      // Format the dates as DD.MM.YYYY
      qParamsSelectedYear.from_date = formatDate(fromDateSelectedYear);
      qParamsSelectedYear.to_date = formatDate(toDateSelectedYear);
      qParamsPrevYear.from_date = formatDate(fromDatePrevYear);
      qParamsPrevYear.to_date = formatDate(toDatePrevYear);

      return { qParamsSelectedYear, qParamsPrevYear };
    },
    // Helper function to calculate the start and end dates of the last quarter
    getLastQuarterDateRange() {
      const currentDate = new Date();

      // Calculate the current quarter (1-based index: Jan-March is Q1)
      const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);

      // Determine the last quarter's year and quarter
      const lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
      const year = currentQuarter === 1 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

      // Calculate the start and end months of the last quarter
      const startMonth = (lastQuarter - 1) * 3; // 0-based month index
      const endMonth = startMonth + 2;

      // Start and end dates
      const from_date = new Date(year, startMonth, 1);
      const to_date = new Date(year, endMonth + 1, 0); // Last day of the quarter

      return {
        from_date: `${from_date.getFullYear()}-${(from_date.getMonth() + 1).toString().padStart(2, '0')}-${from_date.getDate().toString().padStart(2, '0')}`,
        to_date: `${to_date.getFullYear()}-${(to_date.getMonth() + 1).toString().padStart(2, '0')}-${to_date.getDate().toString().padStart(2, '0')}`
      };
    },
    processDataOrdersByCountryGroup(data, value) {
      if (data.length === 0) {
        return { year: null, monthlyData: [], ytdData: [] };
      }
      const currentDate = new Date();
      const currentMonthIndex = currentDate.getMonth();
      const months = [
        new Date(currentDate.getFullYear(), currentMonthIndex - 2, 1),
        new Date(currentDate.getFullYear(), currentMonthIndex - 1, 1),
        new Date(currentDate.getFullYear(), currentMonthIndex, 1)
      ];
      const country_groups = [...new Set(data.map(item => item.country_group))];
      const monthlyData = {};
      const ytdData = {};

      country_groups.sort((a, b) => a.localeCompare(b));

      country_groups.forEach(country_group => {
        monthlyData[country_group] = months.map(month =>
          data
            .filter(item => new Date(item.year, item.month - 1, 1).getTime() === month.getTime() && item.country_group === country_group)
            .reduce((sum, current) => sum + current[value], 0)
        );
        ytdData[country_group] = data
          .filter(item => item.year === currentDate.getFullYear() && item.country_group === country_group)
          .reduce((sum, current) => sum + current[value], 0);
      });

      return { year: currentDate.getFullYear(), monthlyData, ytdData };
    },
    processDataOrdersByPlatform(data, value) {
      if (data.length === 0) {
        return { year: null, monthlyData: [], ytdData: [] };
      }
      const currentYear = new Date().getFullYear();
      const filteredData = data.filter(item => item.year === currentYear);
      const months = [currentMonthIndex - 2, currentMonthIndex - 1, currentMonthIndex];
      const platforms = [...new Set(filteredData.map(item => item.platform))];
      const monthlyData = {};
      const ytdData = {};

      platforms.sort((a, b) => a.localeCompare(b));

      platforms.forEach(platform => {
        monthlyData[platform] = months.map(month =>
          filteredData
            .filter(item => item.month === month && item.platform === platform)
            .reduce((sum, current) => sum + current[value], 0)
        );
        ytdData[platform] = filteredData
          .filter(item => item.platform === platform)
          .reduce((sum, current) => sum + current[value], 0);
      });

      return { year: currentYear, monthlyData, ytdData };
    },
    transformData(arr) {
      return arr.map(item => {
        const result = { label: item.name };
        item.data.forEach((value, index) => {
          result[monthNames[index]] = value.toLocaleString('de-DE');
        });
        return result;
      });
    },
    convertToFloat(value) {
      let formattedValue = value.replace(/\./g, '');
      formattedValue = formattedValue.replace(',', '.');
      return parseFloat(formattedValue);
    },
    findEntryByLabel(data, label) {
      return data.find(entry => entry.label === label);
    },
    formatMonthYear(month, year) {
      return `${allMonthNames[month - 1]}.${year}`
    },
    createComparisonRow(label, baseEntry, comparisonEntry) {
      const row = { label };
      monthNames.forEach((month, index) => {
        if (index < currentMonthIndex) {
          row[month] = (baseEntry && comparisonEntry)
            ? (this.convertToFloat(baseEntry[month]) - this.convertToFloat(comparisonEntry[month])).toLocaleString('de-DE')
            : 0;
        } else {
          row[month] = 0;
        }
      });
      return row;
    },
    addComparisonRows(data) {
      const currentYearEntry = this.findEntryByLabel(data, currentDate.getFullYear());
      const previousYearEntry = this.findEntryByLabel(data, currentDate.getFullYear() - 1);
      const planNumbersEntry = this.findEntryByLabel(data, `${currentDate.getFullYear()} Plan Numbers`);

      const comparisonToPlanNumbers = this.createComparisonRow('Comparison to Plan Numbers', currentYearEntry, planNumbersEntry);
      const comparisonToPrevYear = this.createComparisonRow('Comparison to Prev. Year', currentYearEntry, previousYearEntry);

      return [...data, comparisonToPlanNumbers, comparisonToPrevYear];
    },
    transformDataByCountryGroup(arr, val) {
      const resultMap = new Map();

      arr.forEach(item => {
        const key = this.formatMonthYear(item.month, item.year);
        if (!resultMap.has(key)) {
          resultMap.set(key, { month: item.month, year: item.year, 'Outside EU': 0, 'Germany': 0, 'EU (except Germany)': 0 });
        }
        resultMap.get(key)[item.country_group] = (val === 'turnover' || val === 'offers_value') ? item[val].toLocaleString('de-DE') : item[val];
      });

      return Array.from(resultMap.entries()).map(([key, value]) => ({
        key,
        ...value
      }));
    },
    transformDataByPlatform(arr, val) {
      const resultMap = new Map();

      arr.forEach(item => {
        const key = this.formatMonthYear(item.month, item.year);
        if (!resultMap.has(key)) {
          resultMap.set(key, { month: item.month, year: item.year, 'Amazon': 0, 'Onlineshop': 0, 'JTL-Wawi': 0 });
        }
        resultMap.get(key)[item.platform] = (val === 'turnover') ? item[val].toLocaleString('de-DE') : item[val];
      });

      return Array.from(resultMap.entries()).map(([key, value]) => ({
        key,
        ...value
      }));
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getEmployeeNames() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-support-employee-names/`, {});
        const results = response.data.results;
        this.allEmployees = results
          .filter(employee => employee.full_name !== "Hendirk Homann")
          .filter(employee => employee.full_name !== "Lukas Homann")
          .filter(employee => employee.full_name !== "Angela Melzer")
          .map(employee => employee.full_name);
        this.selectedEmployees = this.allEmployees;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getOrdersOffersByEmployee() {
      this.shoAbsoluteValues = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-employee/`, this.queryParams);
        const results = response.data.results;
        this.ordersOffersByEmployeeTable.items = results;
        this.ordersOffersByEmployeeTable.totalRows = results.length;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.shoAbsoluteValues = false;
      }
    },
    async getTurnoversByEmployees() {
      this.shoDataOverview = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/turnovers-by-employees/`, this.queryParams);
        const resultsTurnovers = response.data.results;

        const resultsTurnoversWithoutShop = resultsTurnovers.filter(item => item.employee !== "Webshop Import");
        let employees = resultsTurnovers.filter(item => item.employee !== "Webshop Import").map(item => item.employee);

        // Current Month
        this.turnoverCurrentMonthChart.series[0].data = resultsTurnovers.map(item => {
          return {
            value: item.turnover_current_month,
            name: item.employee,
          };
        });

        this.turnoverCurrentPrevMonthTable.items = resultsTurnovers;
        this.turnoverCurrentPrevMonthTable.totalRows = resultsTurnovers.length;

        // Month
        const turnoverCurrentYearChartDatasets = [];
        turnoverCurrentYearChartDatasets.push({
          name: 'Turnover Current Year',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: resultsTurnoversWithoutShop.map(e => e.turnover_current_year),
        });
        this.turnoverCurrentYearChart.xAxis.data = employees;
        this.turnoverCurrentYearChart.series = turnoverCurrentYearChartDatasets;

        this.turnoverCurrentYearTable.items = resultsTurnovers;
        this.turnoverCurrentYearTable.totalRows = resultsTurnovers.length;

        // Quarter
        const turnoverLastQuarterChartDatasets = [];
        turnoverLastQuarterChartDatasets.push({
          name: 'Turnover Last Quarter',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: resultsTurnoversWithoutShop.map(e => e.turnover_last_quarter),
        });
        this.turnoverLastQuarterChart.xAxis.data = employees;
        this.turnoverLastQuarterChart.series = turnoverLastQuarterChartDatasets;

        this.turnoverLastQuarterTable.items = resultsTurnovers;
        this.turnoverLastQuarterTable.totalRows = resultsTurnovers.length;

        // Month
        const offersLastMonthChartDatasets = [];
        offersLastMonthChartDatasets.push({
          name: 'Offers Last Month',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: resultsTurnoversWithoutShop.map(e => e.offers_count_last_month),
        });
        this.offersLastMonthChart.xAxis.data = employees;
        this.offersLastMonthChart.series = offersLastMonthChartDatasets;

        this.offersCountLastMonthTable.items = resultsTurnovers;
        this.offersCountLastMonthTable.totalRows = resultsTurnovers.length;

        // Quarter
        const offersLastQuarterChartDatasets = [];
        offersLastQuarterChartDatasets.push({
          name: 'Offers Last Quarter',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: resultsTurnoversWithoutShop.map(e => e.offers_count_last_quarter),
        });
        this.offersLastQuarterChart.xAxis.data = employees;
        this.offersLastQuarterChart.series = offersLastQuarterChartDatasets;

        this.offersCountLastQuarterTable.items = resultsTurnovers;
        this.offersCountLastQuarterTable.totalRows = resultsTurnovers.length;

        // Month
        const ordersLastMonthChartDatasets = [];
        ordersLastMonthChartDatasets.push({
          name: 'Orders Last Month',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: resultsTurnoversWithoutShop.map(e => e.orders_count_last_month),
        });
        this.ordersLastMonthChart.xAxis.data = employees;
        this.ordersLastMonthChart.series = ordersLastMonthChartDatasets;

        this.ordersCountLastMonthTable.items = resultsTurnovers;
        this.ordersCountLastMonthTable.totalRows = resultsTurnovers.length;

        // Quarter
        const ordersLastQuarterChartDatasets = [];
        ordersLastQuarterChartDatasets.push({
          name: 'Orders Last Quarter',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: resultsTurnoversWithoutShop.map(e => e.orders_count_last_quarter),
        });
        this.ordersLastQuarterChart.xAxis.data = employees;
        this.ordersLastQuarterChart.series = ordersLastQuarterChartDatasets;

        this.ordersCountLastQuarterTable.items = resultsTurnovers;
        this.ordersCountLastQuarterTable.totalRows = resultsTurnovers.length;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.shoDataOverview = false;
      }
    },
    async getOrdersIntakeAndFacturizedOrders() {
      this.showOrdersIntakeAndFacturizedOrders = true;

      try {
        const { qParamsSelectedYear, qParamsPrevYear } = this.getFromToDates();

        const requests = [
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/plan-numbers-current-year/`, {}),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-country-group/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-country-group/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-country-group/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-country-group/`, qParamsSelectedYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-platform-and-month/`, qParamsPrevYear),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-platform-and-month/`, qParamsSelectedYear),
        ];

        if (currentDate.getFullYear() === new Date().getFullYear() && currentDate.getMonth() === new Date().getMonth()) {
          let previousYear = currentDate.getFullYear() - 1;
          let previousMonth = currentDate.getMonth();
          let previousDay = currentDate.getDate();
          let day = currentDate.getDate();
          // Check if the current date is Feb 29 and the previous year is not a leap year
          if (previousMonth === 1 && day === 29 && !(previousYear % 4 === 0 && (previousYear % 100 !== 0 || previousYear % 400 === 0))) {
            previousDay = 28; // Adjust day for non-leap year
          }
          const qParamsPrevYearMonthToDate = {
            from_date: `${previousYear}-${previousMonth + 1}-1`,
            to_date: `${previousYear}-${previousMonth + 1}-${previousDay}`,
          };

          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-intake-by-country-group/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-country-group/`, qParamsPrevYearMonthToDate));
          requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/facturized-orders-by-platform-and-month/`, qParamsPrevYearMonthToDate));
        }

        requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/offers-by-country-group-and-month/`, qParamsPrevYear));
        requests.push(this.makeRequest(`${process.env.VUE_APP_ROOT_API}/offers-by-country-group-and-month/`, qParamsSelectedYear));

        const response = await axios.all(requests);

        const data0 = response[0].data.results;
        const data1 = response[1].data.results;
        const data2 = response[2].data.results;
        const data3 = response[3].data.results;
        const data4 = response[4].data.results;
        const data11 = response[11].data.results;
        const data12 = response[12].data.results;

        // Build the groupedOrdersIntake with the correct structure
        const groupedOrdersIntake = [];
        const groupedFacturizedOrders = [];

        // 1. Turnover for previous year
        groupedOrdersIntake.push(data0);
        groupedFacturizedOrders.push(data1);

        // 2. Turnover for current year
        groupedOrdersIntake.push(data2);
        groupedFacturizedOrders.push(data3);

        // 3. MTD value for current month
        groupedOrdersIntake.push([
          { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Empty for two months ago
          { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Empty for one month ago
          data11[0]
        ]);
        groupedFacturizedOrders.push([
          { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Empty for two months ago
          { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Empty for one month ago
          data12[0]
        ]);

        // 4. Plan numbers for current year (taking the last 3 months, including the current month)
        if (data4.oi.length !== 0) {
          groupedOrdersIntake.push([
            data4.oi[currentMonthIndex - 3] || { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Plan for two months ago
            data4.oi[currentMonthIndex - 2] || { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Plan for one month ago
            data4.oi[currentMonthIndex - 1] || { turnover: 0.0, year: currentDate.getFullYear() - 1 }  // Plan for the current month
          ]);
        }
        if (data4.fo.length !== 0) {
          groupedFacturizedOrders.push([
            data4.fo[currentMonthIndex - 3] || { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Plan for two months ago
            data4.fo[currentMonthIndex - 2] || { turnover: 0.0, year: currentDate.getFullYear() - 1 }, // Plan for one month ago
            data4.fo[currentMonthIndex - 1] || { turnover: 0.0, year: currentDate.getFullYear() - 1 }  // Plan for the current month
          ]);
        }

        const ordersIntakeChartDatasets = [];
        for (let i = 0; i < groupedOrdersIntake.length; i++) {
          let label;
          if (i === 2) {
            label = `${groupedOrdersIntake[i][2].year} MTD`;
          } else if (i === 3) {
            label = `${groupedOrdersIntake[i][2].year} Plan Numbers`;
          } else {
            label = groupedOrdersIntake[i][0].year;
          }
          ordersIntakeChartDatasets.push({
            name: label,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? label : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: groupedOrdersIntake[i].map(item => item.turnover),
          });
        }

        const facturizedOrdersChartDatasets = [];
        for (let i = 0; i < groupedFacturizedOrders.length; i++) {
          let label;
          if (i === 2) {
            label = `${groupedFacturizedOrders[i][2].year} MTD`;
          } else if (i === 3) {
            label = `${groupedFacturizedOrders[i][2].year} Plan Numbers`;
          } else {
            label = groupedFacturizedOrders[i][0].year;
          }
          facturizedOrdersChartDatasets.push({
            name: label,
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? label : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: groupedFacturizedOrders[i].map(item => item.turnover),
          });
        }

        this.ordersIntakeChart.series = ordersIntakeChartDatasets;
        this.facturizedOrdersChart.series = facturizedOrdersChartDatasets;

        let ordersIntakeTableDatasets = this.transformData(ordersIntakeChartDatasets);
        let facturizedOrdersTableDatasets = this.transformData(facturizedOrdersChartDatasets);

        // Add new rows for cummulated differences
        ordersIntakeTableDatasets = this.addComparisonRows(ordersIntakeTableDatasets);
        facturizedOrdersTableDatasets = this.addComparisonRows(facturizedOrdersTableDatasets);

        // Table for orders intake
        this.ordersIntakeTable.fields = ['label', ...monthNames];
        this.ordersIntakeTable.items = ordersIntakeTableDatasets;
        this.ordersIntakeTable.totalRows = ordersIntakeTableDatasets.length;

        // Table for facturized orders
        this.facturizedOrdersTable.fields = ['label', ...monthNames];
        this.facturizedOrdersTable.items = facturizedOrdersTableDatasets;
        this.facturizedOrdersTable.totalRows = facturizedOrdersTableDatasets.length;

        // Turnover by country groups
        const { year: year5, monthlyData: data5, ytdData: ytdData5 } = this.processDataOrdersByCountryGroup(response[5].data.results, 'turnover');
        const { year: year6, monthlyData: data6, ytdData: ytdData6 } = this.processDataOrdersByCountryGroup(response[6].data.results, 'turnover');
        const { year: year7, monthlyData: data7, ytdData: ytdData7 } = this.processDataOrdersByCountryGroup(response[7].data.results, 'turnover');
        const { year: year8, monthlyData: data8, ytdData: ytdData8 } = this.processDataOrdersByCountryGroup(response[8].data.results, 'turnover');
        const { year: year13, monthlyData: data13, ytdData: ytdData13 } = this.processDataOrdersByCountryGroup(response[13].data.results, 'turnover');
        const { year: year14, monthlyData: data14, ytdData: ytdData14 } = this.processDataOrdersByCountryGroup(response[14].data.results, 'turnover');

        // Tables for country groups
        let transformedArr1 = this.transformDataByCountryGroup(response[5].data.results, 'turnover');
        let transformedArr2 = this.transformDataByCountryGroup(response[6].data.results, 'turnover');

        // Combine and Sort Data
        let combinedData = [...transformedArr1, ...transformedArr2]
          .sort((a, b) => {
            const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            if (aYear === bYear) {
              return aMonth - bMonth;
            }
            return aYear - bYear;
          });

        // Generate and Sort Headers
        let uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
          const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
          const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
          if (aMonth === bMonth) {
            return aYear - bYear;
          }
          return aMonth - bMonth;
        });

        let headers = ['Label', ...uniqueHeaders];

        // Generate Rows
        let rows = countryGroups.map(group => {
          const row = { 'Label': group };
          uniqueHeaders.forEach(header => {
            const data = combinedData.find(item => item.key === header);
            row[header] = data ? data[group] : '0';
          });
          return row;
        });

        this.ordersIntakeByCountryGroupTable.fields = headers;
        this.ordersIntakeByCountryGroupTable.items = rows;
        this.ordersIntakeByCountryGroupTable.totalRows = rows.length;

        transformedArr1 = this.transformDataByCountryGroup(response[7].data.results, 'turnover');
        transformedArr2 = this.transformDataByCountryGroup(response[8].data.results, 'turnover');

        // Combine and Sort Data
        combinedData = [...transformedArr1, ...transformedArr2]
          .sort((a, b) => {
            const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            if (aYear === bYear) {
              return aMonth - bMonth;
            }
            return aYear - bYear;
          });

        // Generate Rows
        rows = countryGroups.map(group => {
          const row = { 'Label': group };
          uniqueHeaders.forEach(header => {
            const data = combinedData.find(item => item.key === header);
            row[header] = data ? data[group] : '0';
          });
          return row;
        });

        this.facturizedOrdersByCountryGroupTable.fields = headers;
        this.facturizedOrdersByCountryGroupTable.items = rows;
        this.facturizedOrdersByCountryGroupTable.totalRows = rows.length;

        const ordersIntakeByCountryGroupPrevYearDatasets = [];
        Object.keys(data5).forEach((country_group, i) => {
          ordersIntakeByCountryGroupPrevYearDatasets.push({
            name: `${country_group}-${year5}`,
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year5 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data5[country_group],
          });
        });

        const ordersIntakeByCountryGroupPrevYearMTDDatasets = [];
        Object.keys(data13).forEach((country_group, i) => {
          ordersIntakeByCountryGroupPrevYearMTDDatasets.push({
            name: `${country_group}-${year13} MTD`,
            type: 'bar',
            stack: 'Ad2',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? `${year13} MTD` : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data13[country_group],
          });
        });

        const ordersIntakeByCountryGroupThisYearDatasets = [];
        Object.keys(data6).forEach((country_group, i) => {
          ordersIntakeByCountryGroupThisYearDatasets.push({
            name: `${country_group}-${year6}`,
            type: 'bar',
            stack: 'Ad3',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year6 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data6[country_group],
          });
        });

        const facturizedOrdersByCountryGroupPrevYearDatasets = [];
        Object.keys(data7).forEach((country_group, i) => {
          facturizedOrdersByCountryGroupPrevYearDatasets.push({
            name: `${country_group}-${year7}`,
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year7 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data7[country_group],
          });
        });

        const facturizedOrdersByCountryGroupPrevYearMTDDatasets = [];
        Object.keys(data14).forEach((country_group, i) => {
          facturizedOrdersByCountryGroupPrevYearMTDDatasets.push({
            name: `${country_group}-${year14} MTD`,
            type: 'bar',
            stack: 'Ad2',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? `${year14} MTD` : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data14[country_group],
          });
        });

        const facturizedOrdersByCountryGroupThisYearDatasets = [];
        Object.keys(data8).forEach((country_group, i) => {
          facturizedOrdersByCountryGroupThisYearDatasets.push({
            name: `${country_group}-${year8}`,
            type: 'bar',
            stack: 'Ad3',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year8 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data8[country_group],
          });
        });

        this.ordersIntakeByCountryGroupChart.series = [...ordersIntakeByCountryGroupPrevYearDatasets, ...ordersIntakeByCountryGroupThisYearDatasets, ...ordersIntakeByCountryGroupPrevYearMTDDatasets];
        this.facturizedOrdersByCountryGroupChart.series = [...facturizedOrdersByCountryGroupPrevYearDatasets, ...facturizedOrdersByCountryGroupThisYearDatasets, ...facturizedOrdersByCountryGroupPrevYearMTDDatasets];

        // Facturized orders amount by platforms
        const { year: year9, monthlyData: data9, ytdData: ytdData9 } = this.processDataOrdersByPlatform(response[9].data.results, 'turnover');
        const { year: year15, monthlyData: data15, ytdData: ytdData15 } = this.processDataOrdersByPlatform(response[15].data.results, 'turnover');
        const { year: year10, monthlyData: data10, ytdData: ytdData10 } = this.processDataOrdersByPlatform(response[10].data.results, 'turnover');

        const ordersByPlatformAmountPrevYearDatasets = [];
        Object.keys(data9).forEach((platform, i) => {
          ordersByPlatformAmountPrevYearDatasets.push({
            name: `${platform}-${year9}`,
            type: 'bar',
            stack: 'Ad1',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year9 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data9[platform],
          });
        });

        const ordersByPlatformAmountPrevYearMTDDatasets = [];
        Object.keys(data15).forEach((platform, i) => {
          ordersByPlatformAmountPrevYearMTDDatasets.push({
            name: `${platform}-${year15} MTD`,
            type: 'bar',
            stack: 'Ad2',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? `${year15} MTD` : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data15[platform].map((value, index) => index === 2 ? value : 0),
          });
        });

        const ordersByPlatformAmountThisYearDatasets = [];
        Object.keys(data10).forEach((platform, i) => {
          ordersByPlatformAmountThisYearDatasets.push({
            name: `${platform}-${year10}`,
            type: 'bar',
            stack: 'Ad3',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year10 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data10[platform],
          });
        });

        this.facturizedOrdersByPlatformChart.series = [...ordersByPlatformAmountPrevYearDatasets, ...ordersByPlatformAmountThisYearDatasets, ...ordersByPlatformAmountPrevYearMTDDatasets];

        // Table for Orders intake by platform amount
        transformedArr1 = this.transformDataByPlatform(response[9].data.results, 'turnover');
        transformedArr2 = this.transformDataByPlatform(response[10].data.results, 'turnover');

        // Combine and Sort Data
        combinedData = [...transformedArr1, ...transformedArr2]
          .sort((a, b) => {
            const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            if (aYear === bYear) {
              return aMonth - bMonth;
            }
            return aYear - bYear;
          });

        // Generate and Sort Headers
        uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
          const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
          const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
          if (aMonth === bMonth) {
            return aYear - bYear;
          }
          return aMonth - bMonth;
        });

        headers = ['Label', ...uniqueHeaders];

        // Generate Rows
        rows = platforms.map(group => {
          const row = { 'Label': group };
          uniqueHeaders.forEach(header => {
            const data = combinedData.find(item => item.key === header);
            row[header] = data ? data[group] : '0';
          });
          return row;
        });

        this.facturizedOrdersByPlatformTable.fields = headers;
        this.facturizedOrdersByPlatformTable.items = rows;
        this.facturizedOrdersByPlatformTable.totalRows = rows.length;

        // Offers count by country group
        const { year: year16, monthlyData: data16, ytdData: ytdData16 } = this.processDataOrdersByCountryGroup(response[16].data.results, 'offers_count');
        const { year: year17, monthlyData: data17, ytdData: ytdData17 } = this.processDataOrdersByCountryGroup(response[17].data.results, 'offers_count');

        const offersCountByCountryGroupPrevYearDatasets = [];
        Object.keys(data16).forEach((country_group, i) => {
          offersCountByCountryGroupPrevYearDatasets.push({
            name: `${country_group}-${year16}`,
            type: 'bar',
            stack: 'Ad1',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year16 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data16[country_group],
          });
        });

        const offersCountByCountryGroupThisYearDatasets = [];
        Object.keys(data17).forEach((country_group, i) => {
          offersCountByCountryGroupThisYearDatasets.push({
            name: `${country_group}-${year17}`,
            type: 'bar',
            stack: 'Ad2',
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              distance: 5,
              align: 'center',
              verticalAlign: 'middle',
              rotate: 90,
              formatter: params => params.value ? year17 : '',
              fontSize: 10,
              rich: {
                name: {}
              },
            },
            data: data17[country_group],
          });
        });

        this.offersCountByCountryGroupChart.series = [...offersCountByCountryGroupPrevYearDatasets, ...offersCountByCountryGroupThisYearDatasets];

        // Table for offers count by country group
        transformedArr1 = this.transformDataByCountryGroup(response[16].data.results, 'offers_count');
        transformedArr2 = this.transformDataByCountryGroup(response[17].data.results, 'offers_count');

        // Combine and Sort Data
        combinedData = [...transformedArr1, ...transformedArr2]
          .sort((a, b) => {
            const [aMonth, aYear] = a.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            const [bMonth, bYear] = b.key.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) + 1 : parseInt(item));
            if (aYear === bYear) {
              return aMonth - bMonth;
            }
            return aYear - bYear;
          });

        // Generate and Sort Headers
        uniqueHeaders = [...new Set(combinedData.map(item => item.key))].sort((a, b) => {
          const [aMonth, aYear] = a.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
          const [bMonth, bYear] = b.split('.').map((item, index) => index === 0 ? monthNames.indexOf(item) : parseInt(item));
          if (aMonth === bMonth) {
            return aYear - bYear;
          }
          return aMonth - bMonth;
        });

        headers = ['Label', ...uniqueHeaders];

        // Generate Rows
        rows = countryGroups.map(group => {
          const row = { 'Label': group };
          uniqueHeaders.forEach(header => {
            const data = combinedData.find(item => item.key === header);
            row[header] = data ? data[group] : '0';
          });
          return row;
        });

        this.offersCountByCountryGroupTable.fields = headers;
        this.offersCountByCountryGroupTable.items = rows;
        this.offersCountByCountryGroupTable.totalRows = rows.length;

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrdersIntakeAndFacturizedOrders = false;
      }
    },
    async getOrdersByEmployee() {
      this.showOrdersByEmployee = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-employee/`, this.queryParamsOrdersByEmployee);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.ordersByEmployeeTable.items = results;
          this.ordersByEmployeeTable.totalRows = results[0].count * 2;
        }
        else {
          this.ordersByEmployeeTable.items = [];
          this.ordersByEmployeeTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOrdersByEmployee = false;
      }
    },
    async getEmployeeOffers() {
      this.showEmployeeOffers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-offers/`, this.queryParamsEmployeeOffers);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.employeeOffersTable.items = results;
          this.employeeOffersTable.totalRows = results[0].count * 2;
        }
        else {
          this.employeeOffersTable.items = [];
          this.employeeOffersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showEmployeeOffers = false;
      }
    },
    async getEmployeeOrders() {
      this.showEmployeeOrders = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-orders/`, this.queryParamsEmployeeOrders);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.employeeOrdersTable.items = results;
          this.employeeOrdersTable.totalRows = results[0].count * 2;
        }
        else {
          this.employeeOrdersTable.items = [];
          this.employeeOrdersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showEmployeeOrders = false;
      }
    },
    async getEmployeeTopCustomers() {
      this.showTopCustomers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-orders-by-customers/`, this.queryParamsEmployeeTopCustomers);
        const results = response.data.results;
        this.topCustomersChart.xAxis.data = results.map(item => item.customer_name);
        this.topCustomersChart.series[0] = {
          name: "Turnover",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: results.map(item => item.turnover),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showTopCustomers = false;
      }
    },
    async changeSelectedEmployees() {
      this.queryParams.selected_employees = this.selectedEmployees;
      await this.getOrdersOffersByEmployee();
      await this.getTurnoversByEmployees();

      this.$nextTick(() => {
        this.$refs.turnoverCurrentMonthChart.refresh();
        this.$refs.turnoverCurrentYearChart.refresh();
        this.$refs.turnoverLastQuarterChart.refresh();
      });
    },
    async changeDateRange() {
      let fromDateSelectedYear;
      let toDateSelectedYear;
      if (this.selectedDateRange == 'last_six_months') {
        fromDateSelectedYear = `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01`;
        toDateSelectedYear = `${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDay(currentDate)}`;
      }
      else {
        fromDateSelectedYear = `${currentDate.getFullYear()}-01-01`;
        toDateSelectedYear = `${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDay(currentDate)}`;
      }

      const fromDateSelectedYearDate = new Date(fromDateSelectedYear);
      const toDateSelectedYearDate = new Date(toDateSelectedYear);
      const fromDatePrevYearDate = new Date(fromDateSelectedYearDate.getFullYear() - 1, fromDateSelectedYearDate.getMonth(), fromDateSelectedYearDate.getDate());
      const toDatePrevYearDate = new Date(toDateSelectedYearDate.getFullYear() - 1, toDateSelectedYearDate.getMonth(), toDateSelectedYearDate.getDate());

      this.queryParams.from_date = fromDateSelectedYear;
      this.queryParams.to_date = toDateSelectedYear;
      this.queryParams.from_date_prev_year = formatDate(fromDatePrevYearDate);
      this.queryParams.to_date_prev_year = formatDate(toDatePrevYearDate);

      await this.getOrdersOffersByEmployee();
    },
    async turnoverCurrentPrevMonthTableRowClicked(row) {
      const employeeName = row.employee;
      this.modalName = `${employeeName} Orders for Current Month`;
      this.queryParamsOrdersByEmployee = {
        from_date: `${currentDate.getFullYear()}-${formatMonth(currentDate)}-01`,
        to_date: `${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDay(currentDate)}`,
        employee: employeeName,
      };

      await this.getOrdersByEmployee();

      this.$refs['employee-details-modal'].show();
    },
    async turnoverCurrentYearTableRowClicked(row) {
      const employeeName = row.employee;
      this.modalName = `${employeeName} Orders for Current Year`;
      this.queryParamsOrdersByEmployee = {
        from_date: `${currentDate.getFullYear()}-01-01`,
        to_date: `${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDay(currentDate)}`,
        employee: employeeName,
      };

      await this.getOrdersByEmployee();

      this.$refs['employee-details-modal'].show();
    },
    async turnoverLastQuarterTableRowClicked(row) {
      const employeeName = row.employee;
      this.modalName = `${employeeName} Orders for Last Quarter`;
      this.queryParamsOrdersByEmployee = {
        from_date: this.lastQuarterRange.from_date,
        to_date: this.lastQuarterRange.to_date,
        employee: employeeName,
      };

      await this.getOrdersByEmployee();

      this.$refs['employee-details-modal'].show();
    },
    resetEmployeeDetailsModal() {
      this.modalName = '';
    },
    async ordersByEmployeeTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsOrdersByEmployee.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsOrdersByEmployee.ordering = value.sortBy;
      }
      await this.getOrdersByEmployee();
    },
    async handleOrdersByEmployeeTablePageChange(value) {
      this.queryParamsOrdersByEmployee.page = value;
      await this.getOrdersByEmployee();
    },
    async ordersOffersByEmployeeTableRowClicked(row) {
      this.employeeName = row.employee;
      this.queryParamsEmployeeOffers = {
        from_date: this.queryParams.from_date,
        to_date: this.queryParams.to_date,
        employee: this.employeeName,
      };
      this.queryParamsEmployeeOrders = {
        from_date: this.queryParams.from_date,
        to_date: this.queryParams.to_date,
        employee: this.employeeName,
      };
      this.queryParamsEmployeeTopCustomers = {
        from_date: this.queryParams.from_date,
        to_date: this.queryParams.to_date,
        employee: this.employeeName,
      };

      await this.getEmployeeOffers();
      await this.getEmployeeOrders();
      await this.getEmployeeTopCustomers();

      this.$refs['employee-detail-modal'].show();
    },
    async employeeOffersTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsEmployeeOffers.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsEmployeeOffers.ordering = value.sortBy;
      }
      await this.getEmployeeOffers();
    },
    async handleEmployeeOffersTablePageChange(value) {
      this.queryParamsEmployeeOffers.page = value;
      await this.getEmployeeOffers();
    },
    async employeeOrdersTableSortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsEmployeeOrders.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsEmployeeOrders.ordering = value.sortBy;
      }
      await this.getEmployeeOrders();
    },
    async handleEmployeeOrdersTablePageChange(value) {
      this.queryParamsEmployeeOrders.page = value;
      await this.getEmployeeOrders();
    },
    resetEmployeeDetailModal() {
      this.employeeName = '';
    },
    onEmployeeDetailsModalShown() {
      this.$nextTick(() => {
        this.$refs.topCustomersChart.resize();
      });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>